/* Structure */
form.form-search {
  mat-form-field, .form-unit {
    width: calc(50vw - 150px);
    min-width: 300px;
  }

  .content-right {
    text-align: right;
  }
}

.search-result-container {
  position: relative;

  .filter .mat-mdc-form-field {
    font-size: 14px;
    width: 100%;
  }

  .search-result-loading-shade {
    position: absolute;
    top: 78px;
    left: 0;
    bottom: 56px;
    right: 0;
    background: rgba(0, 0, 0, 0.15);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .search-result-table-container {
    position: relative;
    min-height: 280px;
    max-height: 400px;
    overflow: auto;
    width: calc(100vw - 20px);

    table {
      width: 100%;
      border-collapse: collapse;
      table-layout: auto;

      th {
        border: 1px solid #ddd;
        padding: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      td {
        border: 1px solid #ddd;
        padding: 8px;
      }

      tr:nth-child(even){background-color: #f2f2f2;}

      tr:hover {background-color: #ddd;}
    }
  }

  .mdc-data-table__row:last-child .mdc-data-table__cell {
    border-bottom: 1px solid #ddd;
  }
}
