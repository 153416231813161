
.div-label-for-field {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
}

label[required]:after {
  content: " *";
  color: red;
}

.column-md2 {
  float: left;
  width: 50%;

  mat-grid-list {
    mat-grid-tile {
      label {
        width: 100%;
        text-align: right;
        margin-right: 10px;
        margin-top: -20px;
      }

      span {
        width: 100%;
        margin-top: -20px;

        // margin-left: 10px;
        label {
          text-align: left;
        }
      }

      mat-form-field {
        width: 100%;
      }
    }
  }
}

.disable-icon-container {
  text-align: left !important;
}

.root-fullwidth {
  width: 100vw !important;
  max-width: 100vw !important;
}

.root-fullwidth>* {
  width: 100vw !important;
  max-width: 100vw !important;
}

.search-group {
  @media (min-width: 768px) {
    flex: 0 0 auto;
    width: 50%;
    margin-left: 16.66666667%;
  }

  @media (max-width: 767px) {
    display: flex;
    justify-content: flex-end;
  }

  button {
    margin-right: 0.5rem;
  }
}

.button-group {
  @media (min-width: 768px) {
    flex: 0 0 auto;
    margin-left: 16.66666667%;
  }

  @media (max-width: 767px) {
    display: flex;
    justify-content: flex-end;
  }

  button {
    margin-right: 0.5rem;
  }
}

.row.form-group {
  margin-bottom: 1rem;
}

.form-control {
  font-size: 14px;
}

.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.form-note {
  background-color: #5e9cd1 !important;
  color: #fff;
  padding: 8px;
}

.ng-select .ng-select-container {
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
}

app-select.ng-invalid.ng-touched .ng-select-container,
ng-select.ng-invalid.ng-touched .ng-select-container {
  border-color: #dc3545;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
}

.show-feedback.ng-invalid.ng-touched .invalid-feedback {
  display: block;
}

[calendar] {
  position: relative;

  .form-control.is-invalid {
    background-position: right calc(1.4em + 0.1875rem) center;
    background-size: calc(.75em + .95rem) calc(.75em + .375rem);
  }
}

[calendar] input[calendar] {
  padding-right: 1.5em;
}

[calendar] button[calendar] {
  position: absolute;
  top: calc(.25em + .1875rem);
  right: 18px;
  height: 1.4em;
  width: 1.4em;
  padding: 0;
  background-image: url("data:image/svg+xml;uf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-calendar'><rect x='3' y='4' width='18' height='18' rx='2' ry='2'></rect><line x1='16' y1='2' x2='16' y2='6'></line><line x1='8' y1='2' x2='8' y2='6'></line><line x1='3' y1='10' x2='21' y2='10'></line></svg>");
  background-repeat: no-repeat;
  background-position: center;
  background-size: calc(.75em + .95rem) calc(.75em + .375rem);
  border: 0;
  background-color: transparent;
}

app-date-input,
app-datetime-input {
  [calendar] button[calendar] {
    right: 8px;
  }
}

.calendar-month {
  --calendar-month-width: 226px;

  padding: 6px 2px;
  height: 36px;
  width: calc(var(--calendar-month-width) + 4px);

  .ngb-dp-content.ngb-dp-months {
    display: none;
  }

  ngb-datepicker-navigation-select.ngb-dp-navigation-select {
    width: var(--calendar-month-width);
  }

  .ngb-dp-header {
    padding-top: 0px;
    margin-top: -5px;
    background-color: var(--bs-body-bg);

    ngb-datepicker-navigation {
      width: var(--calendar-month-width);
    }

    .ngb-dp-arrow {
      flex: none;
    }
  }
}

.inputControlTime {
  padding-right: calc(1.5em + 0.75rem);
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'><text x='50%' y='50%' font-family='Arial' font-size='16' fill='currentColor' alignment-baseline='middle' text-anchor='middle'>AM</text></svg>");
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.86em + 0.375rem) calc(0.96em + 0.375rem);
}

.inputControlTimeAM {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'><text x='50%' y='50%' font-family='Arial' font-size='16' fill='currentColor' alignment-baseline='middle' text-anchor='middle'>AM</text></svg>");
}

.inputControlTimePM {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'><text x='50%' y='50%' font-family='Arial' font-size='16' fill='currentColor' alignment-baseline='middle' text-anchor='middle'>PM</text></svg>");
}


.bi.bi-trash {
  color: red;
  font-size: 20px;
}

a i.bi {
  font-size: 20px;
}

$bd-dark: lighten(saturate(#212021, 5%), 15%); // stylelint-disable-line function-disallowed-list

.btn-bd-light {
  --btn-custom-color: #{mix($bd-dark, $white, 75%)};

  --bs-btn-color: var(--bs-gray-600);
  --bs-btn-border-color: var(--bs-border-color);
  --bs-btn-hover-color: var(--btn-custom-color);
  --bs-btn-hover-border-color: var(--btn-custom-color);
  --bs-btn-active-color: var(--btn-custom-color);
  --bs-btn-active-bg: var(--bs-white);
  --bs-btn-active-border-color: var(--btn-custom-color);
  --bs-btn-focus-border-color: var(--btn-custom-color);
  --bs-btn-focus-shadow-rgb: var(--bd-dark-rgb);

  i {
    @extend .text-primary
  }
}

.popover-body {
  padding: 0px !important;
}

.fs-large {
  font-size: xx-large;
}

.d-i-flex {
  display: inline-flex;
}

.p-l-4 {
  padding-left: 4px;
}

.v-a-mid {
  vertical-align: middle;
}

.w-2r {
  width: 2rem;
}

.fw-bold {
  font-weight: bold;
}

.c-red {
  color: red;
}

.c-red-24 {
  color: #df0024;
}

.c-blue-DD {
  color: #4989DD;
}

.w-100-i {
  width: 100px !important;
}

.h-75 {
  height: 75px;
}

.f-right {
  float: right;
}

.mr-10 {
  margin-right: 10px;
}

.mr-30 {
  margin-right: 30px;
}

.search-result-container .search-result-table-container {
  width: auto;
  min-height: auto;
}

.search-result-loading-shade {
  z-index: 9999;
}

.cursor-pointer {
  cursor: pointer;
}

.w-200 {
  width: 200px;
}
