/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import 'bootstrap-icons/font/bootstrap-icons.min.css';

// TODO: remove this library when no place using it
@import "@ng-select/ng-select/themes/default.theme.css";

@import "./styles/search-page.scss";
@import "./styles/text.scss";
@import "./styles/table.scss";
@import "./styles/legacy.scss";
.row.form-group {
  margin-bottom: 1rem;
}

label[required]:after {
  content: " *";
  color: red;
}


.form-control {
  font-size: 14px;
}

.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

app-select.ng-invalid.ng-touched .ng-select-container,
ng-select.ng-invalid.ng-touched .ng-select-container {
  border-color: #dc3545;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
}

.ng-select .ng-select-container {
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
}

.show-feedback.ng-invalid.ng-touched .invalid-feedback {
  display: block;
}

.ng-select.ng-select-disabled > .ng-select-container{
  background-color: #e9ecef;
}
